import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import axios from "axios";

import "./AdTable.css";

function ViewAdsByAdmin() {
    const [getaddata, setAddata] = useState([]);

    var date = new Date();

  const getdata = async () => {
    const res = await axios.get("http://localhost:8060/ads/ad/view-admin");
    console.log(res.data);

    if (res.status === 422 || !res) {
      console.log("error ");
    } else {
      setAddata(res.data.getaddata);

      console.log("get data");
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const rejectAd = async (id) => {
    const result = await axios.put(
      `http://localhost:8060/ads/ad/update-status/${id}`,
      { status: "Rejected" }
    );

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Rejected Ad Successfully");
      console.log("Rejected Ad Successfully");
      getdata();
    }
  };

  const approvedAd = async (id) => {
    const result = await axios.put(
      `http://localhost:8060/ads/ad/update-status/${id}`,
      { status: "Approved" }
    );
    console.log("aaaa")

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Approved Ad Successfully");
      console.log("Approved Ad Successfully");
      getdata();
    }
  };

  const pausedAd = async (id) => {
    const result = await axios.put(
      `http://localhost:8060/ads/ad/update-status/${id}`,
      { quickstatus: "Paused"}
    );
    console.log("aaaa")

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Paused Ad Successfully");
      console.log("Paused Ad Successfully");
      getdata();
    }
  };

  const resumedAd = async (id) => {
    const result = await axios.put(
      `http://localhost:8060/ads/ad/update-status/${id}`,
      { quickstatus: "Resumed" }
    );
    console.log("aaaa")

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Resumed Ad Successfully");
      console.log("Resumed Ad Successfully");
      getdata();
    }
  };

  async function deleteRoom(element) {
    try {
      if (window.confirm("This Room Will Be Deleted!")) {
        await axios
          .delete(`http://localhost:8060/ads/ad/delete/${element._id}`)
          .then((res) => {

            if (res.status === 201) {
              alert("Deleted successfully....");
              getdata();
            }
          });
      }
    } catch (error) {

      alert(error);
    }
  }




  return (
    <div className="container" style={{marginLeft:"250px"}}>
      <h1>All ADs</h1>
      <button><a href="/ad/adreport">Report</a></button>
      <div className="row mb-2">
        <div className="col-12">
          {/* <Link to="/addad" className="btn btn-primary">
            New Ad
          </Link> */}
        </div>
      </div>
      <div className="row">
        <div className="col-11">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Room Name</th>
                <th scope="col">Room Type</th>
                <th scope="col">Date</th>
                <th scope="col">User</th>
                <th scope="col">Status</th>
                <th scope="col">Quick Status</th>
                <th scope="col" colSpan="3"></th>
              </tr>
            </thead>
            <tbody>
              {getaddata.map((element, id) => (
                <AdTableRow
                  key={id}
                  id={id}
                  element={element}
                  deleteRoom={deleteRoom}
                  approvedAd={approvedAd}
                  rejectAd={rejectAd}
                  pausedAd={pausedAd}
                  resumedAd={resumedAd}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function AdTableRow({ id, element, deleteRoom, approvedAd, rejectAd, pausedAd, resumedAd }) {
  const { _id, name, desc, date, user, status, quickstatus } = element;

  const formattedDate = new Date(date).toLocaleDateString();

  return (
    <tr>
      <td>
        <Link to={`/view/${_id}`}>{name}</Link>
      </td>
      <td>{desc}</td>
      <td>{formattedDate}</td>
      <td>{user.firstName}</td>
      <td>{status}</td>
      <td>{quickstatus}</td>
      <td>
        <Link to={`/updatead/${_id}`} className="btn btn-secondary mr-2">
          Update
        </Link>
        <button onClick={() => deleteRoom(element)} className="btn btn-danger mr-2">
          Delete
        </button>
        <button
          className="btn btn-success mr-2"
          onClick={() => approvedAd(_id)}
          disabled={status === "Approved"}
        >
          Approve
        </button>
        <button
          className="btn btn-warning mr-2"
          onClick={() => rejectAd(_id)}
          disabled={status === "Rejected"}
        >
          Reject
        </button>
        <button
          className="btn btn-secondary mr-2"
          onClick={() => pausedAd(_id)}
          disabled={quickstatus === "Paused"}
        >
          Pause
        </button>
        <button
          className="btn btn-primary"
          onClick={() => resumedAd(_id)}
          disabled={quickstatus === "Active"}
        >
          Resume
        </button>
      </td>
    </tr>
  );



}
export default ViewAdsByAdmin;
