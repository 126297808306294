import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";
import { CChart } from '@coreui/react-chartjs'
import '../UI/AdminDashboardUi.css'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const AdminDashboard = () => {

  const [AllUsersCount, setAllUsersCount] = useState(0);
  const [AdminUsersCount, setAdminUsersCount] = useState(0);
  const [ClientUsersCount, setClientUsersCount] = useState(0);
  const [AllAdsCount, setAllAdsCount] = useState(0);
  const [ApprovedAdsCount, setApprovedAdsCount] = useState(0);
  const [RejectedCount, setRejectedCount] = useState(0);
  const [PendingCount, setPendingCount] = useState(0);
  const [PausedCount, setPausedCount] = useState(0);
  const [ResumedCount, setResumedCount] = useState(0);
  const [JanuaryCount, setJanuaryCount] = useState(0);
  const [FebruaryCount, setFebruaryCount] = useState(0);
  const [MarchCount, setMarchCount] = useState(0);
  const [AprilCount, setAprilCount] = useState(0);
  const [MayCount, setMayCount] = useState(0);
  const [JuneCount, setJuneCount] = useState(0);
  const [JulyCount, setJulyCount] = useState(0);
  const [AugustCount, setAugustCount] = useState(0);
  const [SeptemberCount, setSeptemberCount] = useState(0);
  const [OctoberCount, setOctoberCount] = useState(0);
  const [NovemberCount, setNovemberCount] = useState(0);
  const [DecemberCount, setDecemberCount] = useState(0);

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  
  const card1 = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          All Users
        </Typography>
        <Typography variant="h5" component="div">
          {AllUsersCount}
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  const card2 = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          All Ads
        </Typography>
        <Typography variant="h5" component="div">
          {AllAdsCount}
        </Typography>
      </CardContent>
    </React.Fragment>
  );

  

  async function getData() {
    try {
      const resultAllUsersCount = await axios.get(
        "http://localhost:8060/user/getallusers-count"
      );
      setAllUsersCount(resultAllUsersCount.data.total);

      const resultAdminUsersCount = await axios.get(
        "http://localhost:8060/user/getadmin-count"
      );
      setAdminUsersCount(resultAdminUsersCount.data.total);

      const resultClientUsersCount = await axios.get(
        "http://localhost:8060/user/getclient-count"
      );
      setClientUsersCount(resultClientUsersCount.data.total);

  const resultAllAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/get-count"
  );
  setAllAdsCount(resultAllAdsCount.data.total);

  const resultApprovedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getapproved-count"
  );
  setApprovedAdsCount(resultApprovedAdsCount.data.total);

  const resultRejectedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getrejected-count"
  );
  setRejectedCount(resultRejectedAdsCount.data.total);

  const resultPendingAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getpending-count"
  );
  setPendingCount(resultPendingAdsCount.data.total);

  const resultPausedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getpaused-count"
  );
  setPausedCount(resultPausedAdsCount.data.total);

  const resultResumedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getresumed-count"
  );
  setResumedCount(resultResumedAdsCount.data.total);

  const resultJanuaryAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getjanuary-count"
  );
  setJanuaryCount(resultJanuaryAdsCount.data.total);

  const resultFebruaryAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getfebruary-count"
  );
  setFebruaryCount(resultFebruaryAdsCount.data.total);

  const resultMarchAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getmarch-count"
  );
  setMarchCount(resultMarchAdsCount.data.total);

  const resultAprilAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getapril-count"
  );
  setAprilCount(resultAprilAdsCount.data.total);

  const resultMayAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getmay-count"
  );
  setMayCount(resultMayAdsCount.data.total);

  const resultJuneAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getjune-count"
  );
  setJuneCount(resultJuneAdsCount.data.total);

  const resultJulyAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getjuly-count"
  );
  setJulyCount(resultJulyAdsCount.data.total);

  const resultAugustAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getaugust-count"
  );
  setAugustCount(resultAugustAdsCount.data.total);

  const resultSeptemberAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getseptember-count"
  );
  setSeptemberCount(resultSeptemberAdsCount.data.total);

  const resultOctoberAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getoctober-count"
  );
  setOctoberCount(resultOctoberAdsCount.data.total);

  const resultNovemberAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getnovember-count"
  );
  setNovemberCount(resultNovemberAdsCount.data.total);

  const resultDecemberAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getdecember-count"
  );
  setDecemberCount(resultDecemberAdsCount.data.total);
    }catch(err){
      console.error(err)
    }
  }

    /* Calling the getData function when the component is mounted. */
  useEffect(() => {
    getData();
  }, []);


  return (
    <div>
      <div className='admin-dashboard-cards'>
      <div className='admin-dashboard-card'>
        <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card1}</Card>
    </Box>
    </div>
    <div className='admin-dashboard-card'>
        <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card2}</Card>
    </Box>
    </div>
    </div>
      <div className='admin-dashoard-container'>
        <div className='admin-dashboard-left-side'>
            <div className='ad-ls-parta'>
                <h1>All Users</h1>
        <CChart
  type="doughnut"
  data={{
    labels: ['Admin', 'Customer'],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651'],
        data: [AdminUsersCount, ClientUsersCount],
      },
    ],
  }}
/>
</div>
<div className='ad-ls-partb'>
    <h1>Monthly Registered Users</h1>
        <CChart
  type="bar"
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: 'No of Users',
        backgroundColor: '#f87979',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 25, 34, 52],
      },
    ],
  }}
  labels="months"
/>
</div>
        </div>
        <div className='admin-dashboard-right-side'>
  <div className='ad-rs-parta'>
    <h1>All Ads</h1>
    <CChart
      type="doughnut"
      data={{
        labels: ['Approved', 'Rejected', 'Pending', 'Paused', 'Resumed'],
        datasets: [
          {
            backgroundColor: ['#E8A87C', '#C38D9E', '#83AF9B', '#F9D949', '#F6AE2D'],
            data: [ApprovedAdsCount, RejectedCount, PendingCount, PausedCount, ResumedCount],
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        animation: {
          animateRotate: true,
          animateScale: true,
        },
      }}
    />
  </div>
  <div className='ad-rs-partb'>
    <h1>Monthly Ads</h1>
    <CChart
      type="bar"
      data={{
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'No of Ads',
            backgroundColor: '#F9D949',
            data: [JanuaryCount, FebruaryCount, MarchCount, AprilCount, MayCount, JuneCount, JulyCount, AugustCount, SeptemberCount, OctoberCount, NovemberCount, DecemberCount],
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
          },
        },
        animation: {
          animateScale: true,
        },
      }}
    />
  </div>
</div>

    </div>
    </div>
  )
}

export default AdminDashboard