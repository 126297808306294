import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";

const AddAd = (props) => {

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const navigate = useNavigate();

  /**
   * When the user clicks the submit button, prevent the default action, then send a Put request to the
   * server with the user's details, and if successful, navigate to the home page.
   */
  const register = async (e) => {
    e.preventDefault();
    try {
      /* Creating an object with the same name as the variables. */
      const AdData = {
        name,
        desc
      };

      /* Sending a post request to the server with the user's details. */
      const result = await axios.post(
        "http://localhost:8060/ads/ad/new",
        AdData
      );
      console.log(AdData)

      /* This is a conditional statement that checks if the status of the response is 200. If it is,
      then it will alert the user that the registration was successful and then it will remove the
      type and status from local storage. It will then navigate to the login page and reload the
      page. */
      if (result?.status === 201) {
        alert("Success");
        /* Reloading the page. */
        navigate("/");
      }
    } catch (err) {
      console.error(err?.response?.data?.errorMessage);
      alert(err?.response?.data?.errorMessage);
    }
  };

  /**
   * When the user clicks the reset button, clear all the form fields.
   */
  const resetForm = (e) => {
    setName("");
    setDesc("");
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
      <Form onSubmit={register} style={{ width: "400px" }}>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Form.Group>

        <Form.Group controlId="desc">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter description"
            required
            onChange={(e) => setDesc(e.target.value)}
            value={desc}
          />
        </Form.Group>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={resetForm}
            variant="secondary"
            size="lg"
            style={{ width: "45%", margin: "5px" }}
          >
            Reset
          </Button>

          <Button
            variant="primary"
            size="lg"
            type="submit"
            style={{ width: "45%", margin: "5px" }}
          >
            Add New AD
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAd;
