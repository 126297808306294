import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrashAlt, FaPause, FaPlay } from "react-icons/fa";

function ViewAdsByClient() {
    const [getaddata, setAddata] = useState([]);

  const getdata = async () => {
    const res = await axios.get("http://localhost:8060/ads/ad/view-client");
    console.log(res.data);

    if (res.status === 422 || !res) {
      console.log("error ");
    } else {
      setAddata(res.data.getaddata);

      console.log("get data");
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pausedAd = async (id) => {
    const result = await axios.put(
      `http://localhost:8060/ads/ad/update-status-client/${id}`,
      { quickstatus: "Paused"}
    );
    console.log("aaaa")

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Paused Ad Successfully");
      console.log("Paused Ad Successfully");
      getdata();
    }
  };

  const resumedAd = async (id) => {
    const result = await axios.put(
      `http://localhost:8060/ads/ad/update-status-client/${id}`,
      { quickstatus: "Resumed" }
    );
    console.log("aaaa")

    if (result.status === 422) {
      console.log("error");
    } else {
      alert("Resumed Ad Successfully");
      console.log("Resumed Ad Successfully");
      getdata();
    }
  };

  async function deleteRoom(element) {
    try {
      if (window.confirm("This Room Will Be Deleted!")) {
        await axios
          .delete(`http://localhost:8060/ads/ad/delete/${element._id}`)
          .then((res) => {

            if (res.status === 201) {
              alert("Deleted successfully....");
              getdata();
            }
          });
      }
    } catch (error) {

      alert(error);
    }
  }

return (
  <div className="container" style={{ marginLeft: "250px" }}>
    <div>
      <table>
        <thead>
          <tr>
            <th scope="col">
              <b>AD ID</b>
            </th>
            <th scope="col">
              <b>AD Name</b>
            </th>
            <th scope="col">
              <b>AD Description</b>
            </th>
            <th scope="col">
              <b>Status</b>
            </th>
            <th scope="col">
              <b>Quick Status</b>
            </th>
            <th scope="col">
              <b>Action</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {getaddata.map((element, id) => {
            const { _id, name, desc, date, user, status, quickstatus } = element;
            return (
              <tr key={id}>
                <td>
                  <Link to={`/view/${element._id}`}>{id}</Link>
                </td>
                <td>{element.name}</td>
                <td>{element.desc}</td>
                <td>{element.status}</td>
                <td>{element.quickstatus}</td>
                <td>
  <button>
    <a href={`/updatead/${element._id}`}>
      <FaEdit />
    </a>
  </button>
  <button onClick={() => deleteRoom(element)}>
    <FaTrashAlt />
  </button>
  <button onClick={() => pausedAd(element._id)} disabled={quickstatus === "Paused"}>
    <FaPause />
  </button>
  <button onClick={() => resumedAd(element._id)} disabled={quickstatus === "Resumed"}>
    <FaPlay />
  </button>
</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
);

}
export default ViewAdsByClient;
