import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ViewAd = (props) => {
  const [ad, setAd] = useState({});

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`http://localhost:8060/ads/ad/view/${id}`)
      .then((res) => {
        setAd(res.data);
      })
      .catch((err) => {
        console.log('Unsucessfully');
      });
  }, [id]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"250px" }}>
      <div style={{ width: '80%', backgroundColor: '#f5f5f5', padding: '25px', borderRadius: '10px', boxShadow: '0px 5px 10px #d3d3d3' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>View Ad</h1>
        <br></br>
        <table style={{ width: '100%', borderCollapse: 'collapse', }}>
          <tbody>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>Name</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.name}</td>
            </tr>
            <tr>
              <td style={{ padding: '10px', backgroundColor: '#fafafa', fontWeight: 'bold' }}>Description</td>
              <td style={{ padding: '10px', backgroundColor: '#ffffff' }}>{ad.desc}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewAd;
