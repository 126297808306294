import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";

const ClientDashboard = () => {

  const [AllClientAdsCount, setAllClientAdsCount] = useState(0);
  const [AllClientApprovedAdsCount, setAllClientApprovedAdsCount] = useState(0);
  const [AllClientRejectedAdsCount, setAllClientRejectedAdsCount] = useState(0);
  const [AllClientPendingAdsCount, setAllClientPendingAdsCount] = useState(0);
  const [AllClientPauseddsCount, setAllClientPauseddsCount] = useState(0);
  const [AllClientResumedAdsCount, setAllClientResumedAdsCount] = useState(0);

  async function getData() {
    try {
  
  const resultAllClientAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getclient-count"
  );
  setAllClientAdsCount(resultAllClientAdsCount.data.total);

  const resultAllClientApprovedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getclientapproved-count"
  );
  setAllClientApprovedAdsCount(resultAllClientApprovedAdsCount.data.total);

  const resultAllClientRejectedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getclientrejected-count"
  );
  setAllClientRejectedAdsCount(resultAllClientRejectedAdsCount.data.total);

  const resultAllClientPendingAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getclientpending-count"
  );
  setAllClientPendingAdsCount(resultAllClientPendingAdsCount.data.total);

  const resultAllClientPausedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getclientpaused-count"
  );
  setAllClientPauseddsCount(resultAllClientPausedAdsCount.data.total);

  const resultAllClientResumedAdsCount = await axios.get(
    "http://localhost:8060/ads/ad/getclientresumed-count"
  );
  setAllClientResumedAdsCount(resultAllClientResumedAdsCount.data.total);
    }catch(err){
      console.error(err)
    }
  }

    /* Calling the getData function when the component is mounted. */
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>ClientDashboard
      <div className="sub-dash3">
                <h1>Total My Ads</h1>
                <span className="dash_value">{AllClientAdsCount}</span>
              </div>
              <div className="sub-dash3">
                <h1>Total My Approved Ads</h1>
                <span className="dash_value">{AllClientApprovedAdsCount}</span>
              </div>
              <div className="sub-dash3">
                <h1>Total My Rejected Ads</h1>
                <span className="dash_value">{AllClientRejectedAdsCount}</span>
              </div>
              <div className="sub-dash3">
                <h1>Total My Pending Ads</h1>
                <span className="dash_value">{AllClientPendingAdsCount}</span>
              </div>
              <div className="sub-dash3">
                <h1>Total My Paused Ads</h1>
                <span className="dash_value">{AllClientPauseddsCount}</span>
              </div>
              <div className="sub-dash3">
                <h1>Total My Resumed Ads</h1>
                <span className="dash_value">{AllClientResumedAdsCount}</span>
              </div>
    </div>
  )
}

export default ClientDashboard