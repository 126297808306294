import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card, Row, Col } from "react-bootstrap";

function ViewAds() {
  const [getaddata, setAddata] = useState([]);

  const getdata = async () => {
    const res = await axios.get("http://localhost:8060/ads/ad/view");
    console.log(res.data);

    if (res.status === 422 || !res) {
      console.log("error ");
    } else {
      setAddata(res.data.getaddata);

      console.log("get data");
    }
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{marginLeft:"250px"}}>
      <div><h1>Vehicle ADs</h1></div>
    <Row xs={1} md={2} lg={3} className="g-4" style={{ margin: "0 auto", marginTop: "50px", maxWidth: "1200px"}}>
      {getaddata.map((element, id) => (
        <Col key={id}>
          <Card>
            <Link to={`/view/${element._id}`}>
              <Card.Img variant="top" src={element.image} alt={element.name} />
              <Card.Body>
                <Card.Title>{element.name}</Card.Title>
                <Card.Text>{element.desc}</Card.Text>
                <Card.Subtitle className="mb-2 text-muted">{element.user.name}</Card.Subtitle>
              </Card.Body>
            </Link>
          </Card>
        </Col>
      ))}
    </Row>
    </div>
  );
}

export default ViewAds;
