import LogOut from "../userManagement/authentication/Logout";
import { FaDashcube } from "react-icons/fa";
import { FaPuzzlePiece } from "react-icons/fa";
import { FaCube } from "react-icons/fa";
import "./styles/SidebarStyles.css";
import AuthContext from "../userManagement/context/UserContext";
import { useContext } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

export default function Sidebar() {

  const drawerWidth = 240;

  const { userType } = useContext(AuthContext);
  var SidebarData;
   if (userType === "Admin") {
    SidebarData = [
      {
        title: "Admin Dashboard",
        path: "/",
        icon: <FaDashcube />,
      },
      {
        title: "Ads Management",
        path: "/viewads-admin",
        icon: <FaPuzzlePiece />,
      },
      {
        title: "User Managenment",
        path: "/users",
        icon: <FaCube />,
      },
      {
        title: "Reports",
        path: "/viewads-admin",
        icon: <FaCube />,
      }
    ];
  } else if (userType === "Client") {
    SidebarData = [
      {
        title: "Home",
        path: "/",
        icon: <FaDashcube />,
      },
      {
        title: "New Ads",
        path: "/viewcwa",
        icon: <FaPuzzlePiece />,
      },
      {
        title: "My Ads",
        path: "/viewads-client",
        icon: <FaCube />,
      },
      {
        title: "Ads",
        path: "/viewads",
        icon: <FaCube />,
      },
      {
        title: "All Chats",
        path: "/viewallcwas",
        icon: <FaCube />,
      }
    ];
  }

  return (
    <div>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        {/* <Toolbar>
          <Typography variant="h6" noWrap component="div">
            Permanent drawer
          </Typography>
        </Toolbar> */}
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          {SidebarData.map((text, index) => (
            <ListItem key={text} disablePadding>
              <li
                key={index}
                onClick={() => (window.location.pathname = text.path)}
                className="sidebar-item"
                id={window.location.pathname === text.path ? "selected" : ""}
              >
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText>
                  {text.title}
                  </ListItemText>
              </ListItemButton>
              </li>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        
        
      </Box>
    </Box>
    </div>
  );
}
